/*
	Phantom by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

(function($) {
	skel.breakpoints({
		xlarge:	'(max-width: 1680px)',
		large:	'(max-width: 1280px)',
		medium:	'(max-width: 980px)',
		small:	'(max-width: 736px)',
		xsmall:	'(max-width: 480px)'
	});

	$(function() {

		var	$window = $(window),
			$body = $('body');

		// Disable animations/transitions until the page has loaded.
			$body.addClass('is-loading');

			$window.on('load', function() {
				window.setTimeout(function() {
					$body.removeClass('is-loading');
				}, 100);
			});

		// Touch?
			if (skel.vars.mobile)
				$body.addClass('is-touch');


		/* menu button */
		$("div.burg").click(function(){
			$("span.menu").toggleClass("open")
		})

	});

    // convert all links to offsite new tab links
    $(function(){
        setTimeout(() => {
            $("a").each((idx, it) => {
                if (it.attributes.href.value.indexOf('http') >= 0) {
                    it.setAttribute("target", '_blank')
                }
            })
        }, 1000)
    })


    $(function(){
        /*
         * matomo tracker using async dynamic image
         */
        // <img referrerpolicy="no-referrer-when-downgrade" src="https://support.evoapps.cloud/ws/evo/?idsite=2&amp;rec=1" style="border:0" alt="" />
        var img = document.createElement("img");
        img.referrerpolicy="no-referrer-when-downgrade"
        img.style="border:0"
        img.alt=""

        var src = '//support.evoapps.cloud/ws/evo/?rec=1'

        function addQueryParam(key, value) {
            src =  src + '&' + key + '=' + encodeURIComponent(value);
        }

        addQueryParam('idsite', 4 );
        addQueryParam('url', location.href);
        addQueryParam('action_name', document.title);
        addQueryParam('rand', Math.floor(Math.random() * 100000000) + '')
        addQueryParam('urlref', document.referrer)

        img.src = src
        document.getElementsByTagName("body")[0].appendChild(img);
    })


})(jQuery);
