'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function PolarSVG(selector, options) {

  if (typeof selector === 'string') {
    this.element = document.querySelector(selector);
  }
  else {
    this.element = selector;
  }

  

  var defaultOptions = {
    size: 500,
    stroke: 3   ,
    arc: false,
    sectorColor: '#aaa',
    sectorStroke: '#eee',
    circleColor: 'none',
    fillCircle: false,
    values: [],
    colours: ['#516F3B', '#426C8D', '#d04e4c', '#DE9603', '#F5D638', '#8F4DAE', '#4464AD', '#A4B0F5', '#F58F29', '#2A816D' ],
    centerFirst: true
  };

  // Merge options with default ones
  options = _extends(defaultOptions, options);


  options.angle = 360.0/options.values.length;

  // Reset stroke to 0 if drawing full sector
  //options.stroke = options.arc ? options.stroke : 0;

  // Circle dimenstions
  options.center = options.size / 2;
  options.radius = options.stroke ? options.center - options.stroke / 2 : options.center;

  var normalised = [];
  var max = -1;
  for(var i=0;i<options.values.length;i++){
      max = Math.max(max, options.values[i]);
  }
  for(var i=0;i<options.values.length;i++){
      var newValue = options.radius * (options.values[i]/max);
      normalised.push(newValue);
  }
  options.values = normalised;
  //console.log(options.values, normalised)



  this.options = options;


  var svg = '<svg class=\'PolarSVG\' viewBox=\'0 0 ' + options.size + ' ' + options.size + '\'>\n      ' + this.getCircle() ;
  
  for(var c=1;c<5;c++){
    svg += this.getGrid(this.options.center, (this.options.radius/4) * c)
  }

  svg += "<text style=\'text-anchor: middle; font-size: 3em; font-weight: bold; fill:#786f6f\' x=" + options.center + "  y=50 >N</text>"

  for(var l=0;l<4;l++){
    svg += this.getCardinalLine(this.options.center, this.options.radius, l)
  }

  for(var i=0;i<options.values.length;i++){
    svg += '\n      ' + this.getSector(i, options.values[i])
  }
  
  svg += '\n    </svg>';

  this.element.innerHTML = svg;
  this.sector = this.element.querySelector('.PolarSVG-sector');
}



PolarSVG.prototype.getSector = function (secIdx, radius) {
  var returnD = false; arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

  var options = this.options;

  // Colors
  var sectorFill =  options.sectorColor;
  var sectorStroke =  options.sectorStroke;

  // Arc angles
  //var firstAngle = (options.angle*secIdx) - 90 ; //options.angle > 180 ? 90 : options.angle - 90;
  //var secondAngle = -270 + options.angle - 180;

  // Arcs
  var firstArc = this.getArc(options.angle, secIdx, radius);
  //var secondArc = options.angle > 180 ? this.getArc(secondAngle, options) : '';

  // start -> starting line
  // end -> will path be closed or not
  var end = '';
  var start = null;

    var rotation = (options.angle*(secIdx))- (90 + (options.centerFirst? options.angle/2 : 0)) ;
    var x = options.center + radius * Math.cos(this.radians(rotation));
    var y = options.center + radius * Math.sin(this.radians(rotation));

 
    start = 'M' + options.center + ',' + options.center + ' L' + x + ',' + y;
    end = 'z';
  

  var d = start + ' ' + firstArc + ' ' + end;

  if (returnD) {
    return d;
  }


  return '<path\n    class=\'PolarSVG-sector\'\n    stroke-width=\'' + options.stroke + '\'\n    fill=' + options.colours[secIdx%options.colours.length] + '\n    stroke=' + sectorStroke + '\n    d=\'' + d + '\' />';
};

PolarSVG.prototype.getCircle = function () {
  var options = this.options;
  var circleFill = options.fillCircle || !options.arc ? options.circleColor : 'none';

  return '<circle\n      class=\'PolarSVG-circle\'\n      stroke-width=\'' + options.stroke + '\'\n      fill=' + circleFill + '\n      stroke=' + options.circleColor + '\n      cx=\'' + options.center + '\'\n      cy=\'' + options.center + '\'\n      r=\'' + options.radius + '\' />';
};

PolarSVG.prototype.getGrid = function (center, radius) {
    return '<circle\n      class=\'PolarSVG-grid\'\n      stroke-width=\'1\'\n      fill=\'none\'\n      stroke=\'#444\'\n      cx=\'' + center + '\'\n      cy=\'' + center + '\'\n      r=\'' + radius + '\' />';
  };

PolarSVG.prototype.getCardinalLine = function (center, radius, inc) {
    var d = 'M' + center + ',' + center + ' l' + ( inc%2 ? 0: (inc%3 ? radius:-radius) ) + ',' + ( inc%2 ? (inc%3 ? radius:-radius):0  );
    return '<path\n    class=\'PolarSVG-cardinal\'\n    stroke-width=\'1\'\n    fill=\'none\'\n      stroke=\'#444\'\n    d=\'' + d + '\' />';
  };

PolarSVG.prototype.getCardinalMarker = function (center, radius, inc, text) {
    var d = 'M' + center + ',' + center + ' l' + ( inc%2 ? 0: (inc%3 ? radius:-radius) ) + ',' + ( inc%2 ? (inc%3 ? radius:-radius):0  );
    return '<text\n    class=\'PolarSVG-cardinal-marker\'\n    stroke-width=\'1\'\n    fill=\'none\'\n      stroke=\'#444\'\n    d=\'' + d + '\' />';
  };

// Generates SVG arc string
PolarSVG.prototype.getArc = function (angle, idx, radius) {
  var options = this.options;

  var rotation = (options.angle*(idx+1)) - (90 + (options.centerFirst? options.angle/2 : 0)) ;

  var x = options.center + radius * Math.cos(this.radians(rotation));
  var y = options.center + radius * Math.sin(this.radians(rotation));

  return 'A' + radius + ',' + radius + ' 1 0,1 ' + x + ',' + y;
};

// Converts from degrees to radians.
PolarSVG.prototype.radians = function (degrees) {
  return degrees / 180 * Math.PI;
};

module.exports = PolarSVG;