import "./styles/main.scss";

require("./font/montserrat/montserrat-v26-latin-regular.woff2");
require("./font/montserrat/montserrat-v26-latin-600.woff2");


import "./js/main.js";

import sparkline from '@fnando/sparkline';

import PolarSVG from "./js/polar.svg";

import tingle from 'tingle.js';

require('tingle.js/dist/tingle.css');

// import {showTooltip, hideTooltip} from './js/tooltip-prompt.js'
import {startCountdowns} from './js/auto-countdown'


import base64 from 'base64-js'

function decode(b64) {
    if (window.TextDecoder) {
        return new TextDecoder().decode(base64.toByteArray(b64))
    } else {
        return atob(b64)
    }
}

(function($){

    $(function(){
        startCountdowns();
    })

    $(function (){

        $(".wind-polar-chart")
            .each(function(it, el){
                var pointsJB64 = $(el).data("points");
                var pointsJStr = decode(pointsJB64);
                var points = JSON.parse(pointsJStr);

                new PolarSVG(el, {
                    values: points,
                    colours: ['#a2a2a2']
                });
            })

        $(".sparkline")
            .each(function(it, el){
                var pointsJB64 = $(el).data("points");
                var pointsJStr = decode(pointsJB64);
                var points = JSON.parse(pointsJStr);

                sparkline(el, points, {normalize: true})
            })
    })


    $(function(){
        var doMore = function(){

            // hideTooltip(); // hide the modal-prompt tooltip

            var widget = $(this)[0];
            var corrId = $(widget).data("corrid");

            var more = $("#more_content #more-" + corrId)

            if(!more || more.length == 0){
                return;
            }

            more = $(more[0]).clone()[0];

            var cpy_holder = $(more).find(".wid_cpy")
            if(cpy_holder){
                var wid_cpy = $(this).clone();
                wid_cpy.addClass('in_modal');
                wid_cpy.find(".tlite").remove();
                $(cpy_holder).append(wid_cpy)
            }


            $(more).show();

            var closers = ['escape', 'button', 'overlay'];

            if($(this).hasClass('widget')){
                //closers.push()
            }

            if ($(this).hasClass('sm-widget')) {
                $("body").addClass("sm-widget"); // css rules for modals in this screen
                var hdr_cpy = $("#main-header").clone()[0];
                $(more).find(".hdr_cpy").append(hdr_cpy)
            }

            var modal = new tingle.modal({
                footer: true,
                stickyFooter: false,
                closeMethods: closers,
                closeLabel: "Close",
                cssClass: [],
                onClose: function(){
                    modal.destroy();
                }
            });

            modal.addFooterBtn('Close', 'tingle-btn tingle-btn--default tingle-btn--pull-right', function() {
                modal.close();
            });


            // set content
            modal.setContent(more);

            // open modal
            modal.open();

        };

        $(".widget").click(doMore);
        $(".sm-widget").click(doMore);


    })



    /**
     * Embedded Styling
     */
    function styleChange(){
        var optionsMatrix = location.hash ? location.hash.substr(1).split(":") : [];

        var framed = optionsMatrix.length > 0 && optionsMatrix[0] == 'framed'
        if(framed){
            $("body").addClass("embedded")
        }
        else {
            $("body").removeClass("embedded")
        }

        var theme = (optionsMatrix.length > 1 && optionsMatrix[1]) || null
        if(theme){
            $("body").addClass(theme)
        }
    }

    styleChange() // run at start

    window.addEventListener("hashchange", styleChange, false);

    /*
    $(function(){

        setTimeout(function(){
            showTooltip();
            setTimeout(function(){
               hideTooltip();
            }, 30000)

        }, 2200);


    });
    */


        /**
    (function manualDemo () {
        var flag = false;

        document.querySelector('.tiles .widget:first-of-type').addEventListener('click', function (e) {
          flag = !flag;

          (flag ? tlite.show : tlite.hide)(e.target);
        });
      }());
      */


})(jQuery);
