function getCountdownFunction(el, countDownDate){
    return function() {

        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        var tpl = window.pageXOffset.i18n.sunset_countdown
        str = tpl.replace("%h%", hours).replace("%m%", minutes);

        // Display the result in the element with id="demo"
        $(el).html(str)
        
    }
}

module.exports = {
    startCountdowns(){
        $("span[data-countdown-to]").each(function(i,el){

            var timestr = $(el).data("countdown-to")
            /** countdown timer */
            // Set the date we're counting down to
            var countDownDate = new Date(timestr).getTime();

            var fn = getCountdownFunction(el, countDownDate);

            // Update the count down every 5 second
            var x = setInterval(fn, 5000);

            fn();
        })
    }

    
}